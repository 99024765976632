export enum Role {
  Admin = 'admin',
  Developer = 'developer',
  GraphicDesigner = 'graphic-designer',
  Operator = 'operator',
  AccountManagerAndOperator = 'account-manager-and-operator',
  OperatorAndGraphicDesigner = 'operator-and-graphic-designer',
  Expediter = 'expediter',
  PrintOperator = 'print-operator',
}

export enum Permission {
  JobRead = 'JobRead',

  Login = 'Login',
  LoginIntranet = 'LoginIntranet',

  MoneyDeliveryTypesRead = 'MoneyDeliveryTypesRead',
  MoneyPaymentTypesRead = 'MoneyPaymentTypesRead',
  NotificationsRead = 'NotificationsRead',

  OrdersReceivedRead = 'OrdersReceivedRead',
  OrdersReceivedReadOpen = 'OrdersReceivedReadOpen',
  OrdersReceivedReadOpenIntranet = 'OrdersReceivedReadOpenIntranet',
  OrdersReceivedReadClosed = 'OrdersReceivedReadClosed',
  OrdersReceivedReadClosedIntranet = 'OrdersReceivedReadClosedIntranet',
  OrdersReceivedManage = 'OrdersReceivedManage',
  OrdersReceivedClose = 'OrdersReceivedClose',
  OrdersReceivedEditPhaseCheck1 = 'OrdersReceivedEditPhaseCheck1',
  OrdersReceivedEditPhaseCheck2 = 'OrdersReceivedEditPhaseCheck2',
  OrdersReceivedEditPhasePlanning = 'OrdersReceivedEditPhasePlanning',
  OrdersReceivedEditPhaseExpedition = 'OrdersReceivedEditPhaseExpedition',
  OrdersReceivedEditPhaseInvoice = 'OrdersReceivedEditPhaseInvoice',
  OrdersReceivedEditPhaseWaitingForInvoice = 'OrdersReceivedEditPhaseWaitingForInvoice',
  OrdersReceivedCountsByPhase = 'OrdersReceivedCountsByPhase',
  OrdersReceivedEditSkipCheck1 = 'OrdersReceivedEditSkipCheck1',

  OrdersReceivedItemRead = 'OrdersReceivedItemRead',
  OrdersReceivedHideFinishedProducts = 'OrdersReceivedHideFinishedProducts',

  ProductsManage = 'ProductsManage',
  ProductsRead = 'ProductsRead',
  ProductsSpecificationAccount = 'ProductsSpecificationAccount',
  ProductsSpecificationCustomer = 'ProductsSpecificationCustomer',
  ProductsASAPManage = 'ProductsASAPManage',
  ProductsCancel = 'ProductsCancel',

  ProductCommentsRead = 'ProductCommentsRead',
  ProductCommentsManage = 'ProductCommentsManage',
  TimeEntriesRead = 'TimeEntriesRead',
  TimeEntriesReadAll = 'TimeEntriesReadAll',
  TimeEntriesManage = 'TimeEntriesManage',
  TimeEntriesManageAll = 'TimeEntriesManageAll',
  TimeEntriesSumsPerDay = 'TimeEntriesSumsPerDay',
  TasksRead = 'TasksRead',
  UsersRead = 'UsersRead',
  UsersManage = 'UsersManage',
  PhaseGroupsRead = 'PhaseGroupsRead',
  PhaseGroupsManage = 'PhaseGroupsManage', // Can edit Phase Groups in the administration
  PhasesRead = 'PhasesRead',
  PhasesManage = 'PhasesManage', // Can edit Phases in the administration

  SettingsRead = 'SettingsRead',
  SettingsManage = 'SettingsManage',

  BullManage = 'BullManage',

  ProductTimeEntriesRead = 'ProductTimeEntriesRead',
  ProductTimeEntriesManage = 'ProductTimeEntriesManage',

  ExportsRead = 'ExportsRead',
}

const defaultPermissions = [
  Permission.JobRead,
  Permission.Login,
  Permission.MoneyDeliveryTypesRead,
  Permission.MoneyPaymentTypesRead,
  Permission.NotificationsRead,
  Permission.OrdersReceivedCountsByPhase,
  Permission.OrdersReceivedItemRead,
  Permission.OrdersReceivedManage,
  Permission.OrdersReceivedRead,
  Permission.OrdersReceivedReadOpenIntranet,
  Permission.PhaseGroupsRead,
  Permission.PhasesRead,
  Permission.ProductCommentsRead,
  Permission.ProductsRead,
  Permission.ProductTimeEntriesRead,
  Permission.TasksRead,
  Permission.TimeEntriesRead,
  Permission.TimeEntriesReadAll,
  Permission.TimeEntriesSumsPerDay,
  Permission.UsersRead,
];

export const Permissions = {
  [Role.GraphicDesigner]: [
    ...defaultPermissions,
    Permission.OrdersReceivedReadOpen,
    Permission.OrdersReceivedReadClosed,
    Permission.ProductCommentsManage,
    Permission.TimeEntriesManage,
  ],
  [Role.Operator]: [...defaultPermissions, Permission.OrdersReceivedReadOpen, Permission.TimeEntriesManage],
  [Role.PrintOperator]: [
    ...defaultPermissions,
    Permission.OrdersReceivedReadOpen,
    Permission.TimeEntriesManage,
    Permission.ProductCommentsManage,
  ],
  [Role.OperatorAndGraphicDesigner]: [
    ...defaultPermissions,
    Permission.OrdersReceivedEditPhaseCheck1,
    Permission.OrdersReceivedEditPhaseCheck2,
    Permission.OrdersReceivedEditPhasePlanning,
    Permission.OrdersReceivedEditPhaseExpedition,
    Permission.OrdersReceivedEditPhaseInvoice,
    Permission.OrdersReceivedEditPhaseWaitingForInvoice,
    Permission.OrdersReceivedReadClosedIntranet,
    Permission.OrdersReceivedHideFinishedProducts,
    Permission.OrdersReceivedReadOpen,
    Permission.ProductCommentsManage,
    Permission.ProductsManage,
    Permission.ProductsSpecificationAccount,
    Permission.TimeEntriesManage,
  ],
  [Role.AccountManagerAndOperator]: [
    ...defaultPermissions,
    Permission.OrdersReceivedEditPhaseCheck1,
    Permission.OrdersReceivedEditPhaseCheck2,
    Permission.OrdersReceivedEditPhasePlanning,
    Permission.OrdersReceivedEditPhaseExpedition,
    Permission.OrdersReceivedEditPhaseInvoice,
    Permission.OrdersReceivedEditPhaseWaitingForInvoice,
    Permission.OrdersReceivedEditSkipCheck1,
    Permission.OrdersReceivedReadClosed,
    Permission.OrdersReceivedReadClosedIntranet,
    Permission.OrdersReceivedReadOpen,
    Permission.OrdersReceivedHideFinishedProducts,
    Permission.ProductCommentsManage,
    Permission.ProductsCancel,
    Permission.ProductsManage,
    Permission.ProductsSpecificationAccount,
    Permission.ProductsSpecificationCustomer,
    Permission.TimeEntriesManage,
  ],
  [Role.Expediter]: [
    ...defaultPermissions,
    Permission.OrdersReceivedReadClosedIntranet,
    Permission.OrdersReceivedEditPhaseExpedition,
    Permission.OrdersReceivedEditPhaseInvoice,
    Permission.OrdersReceivedEditPhaseWaitingForInvoice,
    Permission.ProductsSpecificationAccount,
  ],
  [Role.Admin]: [
    ...defaultPermissions,
    Permission.OrdersReceivedCountsByPhase,
    Permission.OrdersReceivedEditPhaseCheck1,
    Permission.OrdersReceivedEditPhaseCheck2,
    Permission.OrdersReceivedEditPhasePlanning,
    Permission.OrdersReceivedEditPhaseExpedition,
    Permission.OrdersReceivedEditPhaseInvoice,
    Permission.OrdersReceivedEditPhaseWaitingForInvoice,
    Permission.OrdersReceivedEditSkipCheck1,
    Permission.OrdersReceivedReadClosed,
    Permission.OrdersReceivedReadClosedIntranet,
    Permission.OrdersReceivedReadOpen,
    Permission.OrdersReceivedHideFinishedProducts,
    Permission.ProductCommentsManage,
    Permission.ProductTimeEntriesManage,
    Permission.ProductsCancel,
    Permission.ProductsManage,
    Permission.ProductsSpecificationAccount,
    Permission.ProductsSpecificationCustomer,
    Permission.UsersManage,
    Permission.TimeEntriesManage,
    Permission.TimeEntriesManageAll,
    Permission.SettingsRead,
    Permission.SettingsManage,
    Permission.ExportsRead,
  ],
  [Role.Developer]: [
    ...defaultPermissions,
    Permission.BullManage,
    Permission.OrdersReceivedClose,
    Permission.OrdersReceivedEditPhaseCheck1,
    Permission.OrdersReceivedEditPhaseCheck2,
    Permission.OrdersReceivedEditPhasePlanning,
    Permission.OrdersReceivedEditPhaseExpedition,
    Permission.OrdersReceivedEditPhaseInvoice,
    Permission.OrdersReceivedEditPhaseWaitingForInvoice,
    Permission.OrdersReceivedEditSkipCheck1,
    Permission.OrdersReceivedReadClosed,
    Permission.OrdersReceivedReadClosedIntranet,
    Permission.OrdersReceivedReadOpen,
    Permission.OrdersReceivedHideFinishedProducts,
    Permission.PhaseGroupsManage,
    Permission.PhasesManage,
    Permission.ProductTimeEntriesManage,
    Permission.ProductCommentsManage,
    Permission.ProductsCancel,
    Permission.ProductsManage,
    Permission.ProductsSpecificationAccount,
    Permission.ProductsSpecificationCustomer,
    Permission.TimeEntriesManage,
    Permission.TimeEntriesManageAll,
    Permission.UsersManage,
    Permission.SettingsRead,
    Permission.SettingsManage,
    Permission.ExportsRead,
  ],
};

export function deprecatedHasPermission(role: Role, ...permissions: Permission[]): boolean {
  const perms: Permission[] = Permissions[role];
  return !permissions.some((perm) => perms.indexOf(perm) === -1);
}

export function hasPermission(userPermissions: Permission[], permission: Permission | Permission[], all?: boolean) {
  const required = Array.isArray(permission) ? permission : [permission];
  return all ? required.every((p) => userPermissions.includes(p)) : required.some((p) => userPermissions.includes(p));
}
